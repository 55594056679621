<template>
  <a-form :model="formState" class="flex flex-col md:flex-row w-full flex-wrap gap-2">
    <a-form-item label="PERIODO" name="periodo" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo" @select="onSelectPeriodo">
        <a-select-option value="">TODOS</a-select-option>
        <a-select-option
          v-for="periodo in periodosFormateados"
          :value="periodo.idperiodoevaluacion"
          :key="periodo.idperiodoevaluacion"
        >
          {{ periodo.texto }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="PROVINCIA" name="provincia" class="my-1 inline-block w-full md:w-40">
      <a-select v-model:value="formState.provincia" @select="onSelectProvincia">
        <a-select-option key="ALL" value="">TODAS</a-select-option>
        <a-select-option v-for="p in provincias" :value="p.provincia" :key="p.provincia">
          {{ p.provincia }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="DISTRITO" name="distrito" class="my-1 inline-block w-full md:w-52">
      <a-select v-model:value="formState.distrito" @select="onSelectDistrito">
        <a-select-option key="all" value="">TODOS</a-select-option>
        <a-select-option v-for="d in distritos" :value="d.idubigeoinei" :key="d.idubigeoinei">
          {{ d.distrito }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <div id="quintil" style="display: none">
      <a-form-item label="QUINTIL 1 Y 2" name="quintil" class="my-1 inline-block md:w-40">
        <a-select v-model:value="formState.quintil" @select="onSelectQuintil">
          <a-select-option key="" value="">TODOS</a-select-option>
          <a-select-option value="1">SI</a-select-option>
          <a-select-option value="0">NO</a-select-option>
        </a-select>
      </a-form-item>
    </div>
  </a-form>
</template>

<script>
import { ref, reactive, onMounted, computed, toRaw } from "vue";
import IndicadorApi from "@/api/indicador";
import { generaLabelPeriodoFormateado } from "@/utils";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  props: {
    periodos: {
      type: Array,
      default: () => []
    },
    idperiodoinicio: {
      type: String,
      default: ""
    }
  },
  emits: ["changedistrito", "changePeriodo", "changeprovincia"],
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const provincias = ref([]);
    const distritos = ref([]);
    const idindicador = ref("");

    idindicador.value = route.params?.indicador;

    const formState = reactive({
      idperiodo: props.idperiodoinicio,
      provincia: "",
      distrito: "",
      quintil: ""
    });

    const periodosFormateados = computed(() => {
      const tempPeriodos = toRaw(props.periodos);
      const periodosReverso = tempPeriodos.reverse();
      return generaLabelPeriodoFormateado(periodosReverso);
    });

    const fetchProvincias = () => {
      IndicadorApi.getProvincias()
        .then((response) => {
          provincias.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchDistritos = (provincia = "") => {
      IndicadorApi.getDistritos(provincia)
        .then((response) => {
          distritos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {

      fetchProvincias();
      fetchDistritos();

      setTimeout(() => {
        formState.idperiodo = props.idperiodoinicio;
        store.dispatch("detalleindicador/setPeriodo", formState.idperiodo);
        emit("changePeriodo");

        var periodo_new = props.periodos.filter(per => per.idperiodoevaluacion == props.idperiodoinicio)[0];

        store.dispatch("graficasEstablecimientos/setPeriodoIni", periodo_new.idperiodoinicio);
        store.dispatch("graficasEstablecimientos/setPeriodoFin", periodo_new.idperiodofinal);
      }, 1500);

      if (idindicador.value == 14 || idindicador.value == 15) {
        document.getElementById("quintil").style.display = "block";
        store.dispatch("mapa/setQuintil", "1");
        formState.quintil = "1";
      }
    });

    const onSelectProvincia = (provincia) => {
      store.dispatch("mapa/setProvincia", provincia);
      store.dispatch("mapa/setUbigeo", "");
      store.dispatch("mapa/setDistrito", "");

      formState.distrito = "";
      emit("changeprovincia");
      fetchDistritos(provincia);
    };

    const onSelectPeriodo = (periodo, event) => {

      if(periodo != "") 
      {
        var periodo_new = props.periodos.filter(per => per.idperiodoevaluacion == periodo)[0];

        store.dispatch("graficasEstablecimientos/setPeriodoIni", periodo_new.idperiodoinicio);
        store.dispatch("graficasEstablecimientos/setPeriodoFin", periodo_new.idperiodofinal);
      }

      store.dispatch("mapa/setPeriodo", periodo);
      store.dispatch("detalleindicador/setPeriodo", event.key);

      emit("changePeriodo");
    };

    const onSelectDistrito = (ubigeo) => {
      store.dispatch("mapa/setUbigeo", ubigeo);
      emit("changedistrito");
    };

    const cleanFilters = () => {
      formState.idperiodo = props.idperiodoinicio;
      formState.provincia = "";
      formState.distrito = "";

      store.dispatch("mapa/setProvincia", "");
      store.dispatch("mapa/setUbigeo", "");

      emit("changedistrito");
      emit("changePeriodo");
      emit("changeprovincia");

      fetchProvincias();
      fetchDistritos();
    };

    const onSelectQuintil = (quintil) => {
      store.dispatch("mapa/setQuintil", quintil);
      emit("changePeriodo");
    };

    return {
      formState,
      provincias,
      distritos,
      onSelectPeriodo,
      onSelectProvincia,
      onSelectDistrito,
      cleanFilters,
      periodosFormateados,
      onSelectQuintil
    };
  }
};
</script>

<style scoped></style>
