<template>
  <PrintContainer tituloReporte="RESUMEN DE INDICADOR">
    <slot name="header"></slot>

    <slot name="grafico"></slot>

    <table class="items-center w-full">
      <thead class="theadprint text-xs p-5">
        <tr>
          <th class="text-center p-3 border border-black">NRO</th>
          <th class="text-center p-3 border border-black">MICRORED</th>
          <th class="text-center p-3 border border-black">ESTABLECIMIENTO</th>
          <th class="text-center p-3 border border-black">NUM/DEN</th>
          <th class="text-center p-3 border border-black">PORCENTAJE</th>
        </tr>
      </thead>
      <tbody class="">
        <tr v-for="(indicador, index) in indicadorEstablecimientos" :key="index">
          <td class="border border-gray-900 align-middle text-center">
            {{ index + 1 }}
          </td>
          <td class="border border-gray-900 align-middle px-2">
            {{ indicador.microred }}
          </td>
          <td class="border border-gray-900 align-middle px-2">
            {{ indicador.establecimiento }}
          </td>
          <td class="border border-gray-900 align-middle px-2">
            {{ indicador.total_numerador_establecimiento }} /
            {{ indicador.total_denominador_establecimiento }}
          </td>

          <td class="border border-gray-900 align-middle px-2">
            {{
              calculaPorcentaje(
                indicador.total_numerador_establecimiento,
                indicador.total_denominador_establecimiento
              )
            }}
          </td>
        </tr>
      </tbody>
      <PrintFooter />
    </table>
  </PrintContainer>
</template>

<script>
import PrintContainer from "./PrintContainer.vue";
import PrintFooter from "@/components/PrintFooter.vue";
import { calculaPorcentaje } from "@/utils";

import { ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PrintContainer,
    PrintFooter
  },
  props: {
    indicadorEstablecimientos: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const store = useStore();

    const search = ref(store.getters["indicador/search"]);
    const filter = ref(store.getters["indicador/filter"]);

    return {
      search,
      filter,
      calculaPorcentaje
    };
  }
};
</script>

<style scoped>
table {
  page-break-inside: auto;
  page-break-before: avoid;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
</style>
