import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";
const API_SERVICE_DELETE = "/api/sivi_data/V1/delete";
const API_SERVICE_VALIDAR_FECHAS = "/api/Service/V1/validar_fechas";

/**
 * Estados de ejecucion del proceso de evaluacion
 * 1: poner estado
 * 2: otro estado
 * 3: otro estado
 */
export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_periodoevaluacion",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        }
      ]
    };

    if (payload.filter !== "all") {
      const { filter, search } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "p.activo",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${filter}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "idperiodoinicio|idperiodofinal",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    } else if (payload.search) {
      const { search } = payload;

      request._petitions.push({
        name: "@filtros_OR",
        value: "idperiodoinicio|idperiodofinal",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${search}%|%${search}%`,
        type: "string"
      });
    }
    return post(API_SERVICE_QUERY, request);
  },

  getAllPeriodo: (idindicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_ind_periodoevaluacion",
          type: "string"
        },
        {
          name: "@idindicador",
          value: `${idindicador}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  deletePeriodo: (idperiodo) => {
    const request = {
      _type: 3,
      _table: "ind_periodoevaluacion",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    console.log(request);
    return put(API_SERVICE + "/" + idperiodo, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "ind_periodoevaluacion",
      _petitions: [
        {
          name: "fechainicio",
          value: payload.fechainicio,
          type: "string"
        },
        {
          name: "fechafinal",
          value: payload.fechafinal,
          type: "string"
        },
        {
          name: "idperiodoinicio",
          value: payload.idperiodoinicio,
          type: "string"
        },
        {
          name: "idperiodofinal",
          value: payload.idperiodofinal,
          type: "string"
        },
        {
          name: "idindicador",
          value: payload.idindicador,
          type: "string"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_VALIDAR_FECHAS, request);
  },

  deletedataperiodo: (idperiodo, tabla) => {
    const request = {
      _type: 1,
      _table: tabla,
      _petitions: [
        {
          name: "idperiodoevaluacion",
          value: idperiodo,
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE_DELETE, request);
  }
};
