<template>
  <a-spin :indicator="indicator" />
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { h } from "vue";

export default {
  setup() {
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: "50px"
      },
      spin: true
    });
    return {
      indicator
    };
  }
};
</script>

<style></style>
