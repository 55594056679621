<template>
  <a-modal
    :visible="true"
    title="Periodo de evaluación"
    width="700px"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    okText="Crear y ejecutar"
    :destroyOnClose="true"
    :okButtonProps="{ disabled: isExecuteDisabled }"
  >
    <a-form :model="formState" ref="formRef" layout="horizontal">
      <a-form-item label="Selecciona el periodo de evaluación" name="periodo">
        <a-range-picker v-model:value="formState.periodo" format="DD/MM/YYYY" />
      </a-form-item>
    </a-form>

    <button @click="handleDelete" class="text-center px-3" :disabled="isAccionsDisabled">
      <div
        class="text-3xl"
        :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-red-400']"
      >
        <DeleteOutlined />
      </div>
      <p>Eliminar</p>
    </button>

    <button @click="handleReprocess" class="text-center px-3" :disabled="isAccionsDisabled">
      <div
        class="text-3xl"
        :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-green-600']"
      >
        <ReloadOutlined />
      </div>
      <p>Reprocesar</p>
    </button>

    <a-table
      :row-selection="rowSelection"
      :columns="columnsPeriodoEvaluacion"
      :data-source="periodoDateFormated"
      size="small"
      :pagination="true"
      :custom-row="customRow"
      rowKey="idperiodoevaluacion"
      :rowClassName="
        (record, index) =>
          index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
      "
    >
      <template #rangepages="{ index }">
        <span> {{ index + 1 }} </span>
      </template>

      <template #ejecucion="{ text: ejecucion }">
        <a-tag v-if="ejecucion === '1'" color="success">
          <template #icon>
            <CheckCircleOutlined />
          </template>
          exitoso
        </a-tag>

        <a-tag v-if="ejecucion == '0'" color="processing">
          <template #icon>
            <SyncOutlined :spin="true" />
          </template>
          procesando
        </a-tag>

        <a-tag v-if="ejecucion === '3'" color="error">
          <template #icon>
            <CloseCircleOutlined />
          </template>
          error
        </a-tag>
      </template>
    </a-table>
    <!-- :loading="isLoading" -->
  </a-modal>
</template>

<script>
import { ref, reactive, computed, toRaw, onMounted, createVNode, watch, onUnmounted } from "vue";
import PeriodoEvaluacionApi from "@/api/periodoevaluacion";
import ApiIndicador from "@/api/indicador";
import { Modal, notification } from "ant-design-vue";
import { columnsPeriodoEvaluacion } from "./utilsIndicador";
import { useStore } from "vuex";
import moment from "moment";
import {
  DeleteOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined
} from "@ant-design/icons-vue";

export default {
  props: {
    query: {
      type: String,
      default: ""
    },
    sigla: {
      type: String,
      default: ""
    },
    idindicador: {
      required: true,
      type: String,
      default: ""
    }
  },
  components: {
    // icons
    DeleteOutlined,
    CloseCircleOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    SyncOutlined
  },
  emits: ["closeModal", "getResumenIndicador"],
  setup(props, { emit }) {
    const dateFormat = "YYYY-MM-DD";
    const periodosEvaluacion = ref([]);
    const onePeriodoEvaluacion = ref(null);
    const formRef = ref();
    const intervalId = ref("");
    const isAccionsDisabled = ref(true);
    const isExecuteDisabled = ref(true);
    const store = useStore();
    const state = reactive({
      selectedRowKeys: []
    });

    /* eslint-disable  vue/no-setup-props-destructure*/
    const query = props.query;
    const sigla = props.sigla;
    const idindicador = props.idindicador;

    const formState = reactive({
      periodo: [],
      fechainicio: "",
      fechafin: "",
      idperiodoinicio: "",
      idperiodofinal: ""
    });

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = async () => {
      const payload = {
        fechainicio: formState.periodo[0].format("YYYY-MM-DD h:mm:ss a"),
        fechafinal: formState.periodo[1].format("YYYY-MM-DD h:mm:ss a"),
        idperiodoinicio: formState.periodo[0].format("YYYY") + formState.periodo[0].format("MM"),
        idperiodofinal: formState.periodo[1].format("YYYY") + formState.periodo[1].format("MM"),
        idindicador
      };

      const periodos = toRaw(periodosEvaluacion.value);

      var f_Inicio = moment(payload.fechainicio);
      var f_Fin = moment(payload.fechafinal);

      var rango_fechas = 0;

      periodos.forEach((element) => {
        var p_Inicio = moment(element.fechainicio);
        var p_Fin = moment(element.fechafinal);

        if (p_Inicio <= f_Inicio && f_Inicio <= p_Fin) rango_fechas = 1;
        if (p_Inicio <= f_Inicio && f_Fin <= p_Fin) rango_fechas = 1;
      });

      if (rango_fechas == 1) {
        notification.error({
          message: "Ocurrio un error",
          description: "Fechas no válidas"
        });
      } else {
        try {
          const response = await PeriodoEvaluacionApi.addOne(payload);

          const newid = response.data[0].id;
          if (newid) {
            await ApiIndicador.executeScript(
              query,
              payload.fechainicio,
              payload.fechafinal,
              newid,
              idindicador
            );

            fetchPeriodosEvaluacion();

            notification.success({
              message: "Operación exitosa",
              description: `La query del indicador id ${idindicador} se ejecutó satisfactoriamente.`
            });
          }
        } catch (error) {
          notification.error({
            message: "Ocurrio un error",
            description: error.message
          });
        }
      }
    };

    // -------- para selecionar los datos de la tabla --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          onePeriodoEvaluacion.value = toRaw(selectedRows[0]);
        }
      };
    });

    const customRow = () => {
      return {
        onDblclick: () => {
          clearSelectedRowkey();
        }
      };
    };

    const clearSelectedRowkey = () => {
      state.selectedRowKeys = [];
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const fetchPeriodosEvaluacion = () => {
      PeriodoEvaluacionApi.getAllPeriodo(idindicador).then((response) => {
        console.log("PeriodoEvaluacionApi.getAllPeriodo", response);

        periodosEvaluacion.value = response.data;
        const existPeriodoProcesing = !!periodosEvaluacion.value.filter((p) => p.ejecucion === "0")
          .length;

        store.dispatch("periodoevaluacion/setProcesing", existPeriodoProcesing);
      });
    };

    const getDataFrequently = () => {
      intervalId.value = setInterval(() => {
        fetchPeriodosEvaluacion();
      }, 2500);
    };

    onMounted(() => {
      fetchPeriodosEvaluacion();
      getDataFrequently();
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
    });

    const handleReprocess = () => {
      const idperiodoevaluacion = onePeriodoEvaluacion.value.idperiodoevaluacion;
      const fechaInicio = onePeriodoEvaluacion.value.fechainicio;
      const fechaFinal = onePeriodoEvaluacion.value.fechafinal;

      ApiIndicador.executeScript(query, fechaInicio, fechaFinal, idperiodoevaluacion, idindicador)
        .then(() => {
          notification.success({
            message: "Operación exitosa",
            description: "Script ejecutado."
          });

          fetchPeriodosEvaluacion();
        })
        .catch((err) => console.log(err));
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          `Se eliminará el periodo de evaluación ${onePeriodoEvaluacion.value.rangoperiodo}`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        async onOk() {
          const idperiodoevaluacion = onePeriodoEvaluacion.value.idperiodoevaluacion;

          try {
            await PeriodoEvaluacionApi.deletedataperiodo(idperiodoevaluacion, sigla);
            await PeriodoEvaluacionApi.deletePeriodo(idperiodoevaluacion);
            clearSelectedRowkey();
            fetchPeriodosEvaluacion();
            notification.success({
              message: "Operación exitosa",
              description: "Periodo de evaluación eliminado correctamente."
            });
            emit("getResumenIndicador");
          } catch (err) {
            notification.error({
              message: "Ocurrio un error",
              description: err.message
            });
          }
        }
      });
    };

    watch(state, () => {
      isAccionsDisabled.value = !state.selectedRowKeys.length;
    });

    watch(formState, () => {
      isExecuteDisabled.value = !formState.periodo.length;
    });

    const periodoDateFormated = computed(() =>
      periodosEvaluacion.value.map((d) => {
        return {
          ...d,
          fechainicio_f: moment(d.fechainicio).format("L"),
          fechafinal_f: moment(d.fechafinal).format("L"),
          rangoperiodo: d.idperiodoinicio + "-" + d.idperiodofinal
        };
      })
    );

    return {
      isAccionsDisabled,
      isExecuteDisabled,
      handleOk,
      closeModal,
      handleCancel,
      handleDelete,
      rowSelection,
      customRow,
      // data,
      periodoDateFormated,
      columnsPeriodoEvaluacion,
      formRef,
      formState,
      dateFormat,
      handleReprocess
    };
  }
};
</script>
